import axios from "axios";
import { FC, FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api/adminBaseApi";
import { useAuth } from "../../context/auth";
import InputField from "../InputField/InputField";
import Offering from "../Offering/Offering";
import styles from "./styles.module.scss";

interface LogInProps {}

export const Back = ({
	to,
	fixed,
	className,
}: {
	to: string;
	fixed?: boolean;
	className?: string;
}) => (
	<NavLink
		to={to}
		className={`${styles.back} ${className}`}
		style={{ position: fixed ? "fixed" : "absolute" }}
	>
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M14.0931 8.25395H5.71562L9.37562 4.59395C9.66812 4.30145 9.66812 3.82145 9.37562 3.52895C9.08312 3.23645 8.61063 3.23645 8.31813 3.52895L3.37562 8.47145C3.08313 8.76395 3.08313 9.23644 3.37562 9.52894L8.31813 14.4714C8.61063 14.7639 9.08312 14.7639 9.37562 14.4714C9.66812 14.1789 9.66812 13.7064 9.37562 13.4139L5.71562 9.75395H14.0931C14.5056 9.75395 14.8431 9.41645 14.8431 9.00395C14.8431 8.59145 14.5056 8.25395 14.0931 8.25395Z" />
			<path
				d="M5.36207 7.90039L4.50852 8.75395H5.71562H14.0931C14.2295 8.75395 14.3431 8.86759 14.3431 9.00395C14.3431 9.1403 14.2295 9.25395 14.0931 9.25395H5.71562H4.50852L5.36207 10.1075L9.02207 13.7675C9.11931 13.8647 9.11931 14.0207 9.02207 14.1179C8.92483 14.2151 8.76892 14.2151 8.67168 14.1179L3.72918 9.17539C3.63194 9.07815 3.63194 8.92224 3.72918 8.825L8.67168 3.8825C8.76892 3.78526 8.92483 3.78526 9.02207 3.8825C9.11931 3.97974 9.11931 4.14315 9.02207 4.24039L5.36207 7.90039Z"
				strokeOpacity="0.88"
			/>
		</svg>

		<p>Back</p>
	</NavLink>
);

const LogIn: FC<LogInProps> = (): JSX.Element => {
	const navigate = useNavigate();
	const { setUserData, setIsLoggedIn } = useAuth();
	const [formData, setFormData] = useState({
		email: "",
		password: "",
	});
	const [errors, setErrors] = useState({
		email: "",
		password: "",
	});

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setFormData({
			...formData,
			[name]: e.target.value,
		});

		if (e.target.value === "") {
			setErrors({ ...errors, [name]: `${name} cannot be empty` });
		} else {
			setErrors({ ...errors, [name]: "" });
		}
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		toast.promise(
			axios.post(API_BASE_URL + "Auth/login", formData, {
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			}),
			{
				loading: "Logging in...",
				success: (res) => {
					if (res.data.isSuccessful) {
						setUserData(res.data);
						setIsLoggedIn(true);

						localStorage.setItem("isLoggedIn", "true");

						navigate("/dashboard");

						return res.data.remark || "Log in successfully!";
					}

					if (res.data.registrationStatus === 1) {
						setUserData(res.data);

						navigate("/verification");
					}

					if (res.data.registrationStatus === 2) {
						setUserData(res.data);

						if (res.data.isIndividual)
							navigate("/sign-up/individual/personal-information");
						else navigate("/sign-up/corporate/business-information");
					}

					if (res.data.registrationStatus === 3) {
						setUserData(res.data);

						if (res.data.isIndividual)
							navigate(
								"/sign-up/individual/personal-information/country-information"
							);
						else
							navigate(
								"/sign-up/corporate/business-information/country-information"
							);
					}

					if (
						res.data.registrationStatus === 0 &&
						res.data.userRole === "Staff" &&
						res.data.isIndividual
					) {
						setUserData(res.data);

						navigate("/sign-up/individual/personal-information");
					}

					throw new Error(
						res.data.remark || res || "An error occurred, please try again!"
					);
				},
				error: (err) => {
					if (axios.isAxiosError(err)) {
						if (err.response?.data.registrationStatus === 1) {
							setUserData(
								err.response?.data?.email || { email: formData.email }
							);

							navigate("/verification");
						}

						if (err.response?.data.registrationStatus === 2) {
							setUserData(err.response?.data);

							if (err.response?.data.isIndividual)
								navigate("/sign-up/individual/personal-information");
							else navigate("/sign-up/corporate/business-information");
						}

						if (err.response?.data.registrationStatus === 3) {
							setUserData(err.response?.data);

							if (err.response?.data.isIndividual)
								navigate(
									"/sign-up/individual/personal-information/country-information"
								);
							else
								navigate(
									"/sign-up/corporate/business-information/country-information"
								);
						}

						return (
							err.response?.data.title ||
							err.response?.data.remark ||
							err.message
						);
					}

					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.logIn}>
			<div className={styles.left}>
				<Offering />
			</div>

			<div className={styles.right} id="mainContainer">
				<Back to="/" className={styles.back} />

				<div className={styles.content}>
					<h6>Log In</h6>

					<form onSubmit={handleSubmit}>
						<InputField
							label="Email"
							name="email"
							type="email"
							placeholder="name@example.com"
							value={formData.email}
							onChange={handleInputChange}
							error={errors.email}
							isRequired
						/>

						<InputField
							label="Password"
							name="password"
							type="password"
							placeholder="Enter your password"
							value={formData.password}
							onChange={handleInputChange}
							error={errors.password}
							isRequired
						/>

						<NavLink to="/forgot-password" className={styles.forgot}>
							Forgot Password?
						</NavLink>

						<button className="btn" type="submit">
							Log In
						</button>

						<div className={styles.or}>
							<div />
							<p>or</p>
							<div />
						</div>

						<p className={styles.formFooter}>
							<span>Don’t have an account?</span>{" "}
							<NavLink to="/sign-up">Sign Up</NavLink>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LogIn;
