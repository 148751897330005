import axios from "axios";
import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api/adminBaseApi";
import Logo from "../../assets/images/Logo.png";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import UploadBox from "../InputField/UploadBox";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

const PersonalInformation: FC = (): JSX.Element => {
	const { setUserData, userData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const navigate = useNavigate();
	const [businessTypes, setBusinessTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();
	const [identificationTypes, setIdentificationTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();
	const [proofOfIdentification, setProofOfIdentification] = useState<File>();
	const [formData, setFormData] = useState({
		firstName: userData?.firstName || "",
		phoneNumber: userData?.phoneNumber || "",
		lastName: userData?.lastName || "",
		middleName: userData?.middleName || "",
		isStaff: userData?.userRole === "Staff" ? "Yes" : "",
		dateOfBirth: "",
		bvn: userData?.bvn || "",
		nin: userData?.nin || "",
		businessName: userData?.businessName || "",
		businessType: userData?.businessType || "",
		identificationType: userData?.identificationType || "",
		identificationId: userData?.identificationId || "",
	});

	const [errors, setErrors] = useState({
		firstName: "",
		phoneNumber: "",
		lastName: "",
		isStaff: "",
		dateOfBirth: "",
		nin: "",
		bvn: "",
		businessName: "",
		businessType: "",
		identificationType: "",
		identificationId: "",
	});

	useEffect(() => {
		const fetchData = async () => {
			const businessTypesRes = await axiosFetch({
				method: "GET",
				url: "/BusinessTypes/GetBusinessTypeByName?pageNumber=1&pageSize=100",
			});

			setBusinessTypes(
				businessTypesRes?.businessTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);

			const identificationTypesRes = await axiosFetch({
				method: "GET",
				url: "/IdentificationTypes/GetIdentificationTypes?pageNumber=1&pageSize=100",
			});

			setIdentificationTypes(
				identificationTypesRes?.identificationTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!userData?.publicId) {
			navigate("/sign-up/individual");

			toast.error("You are not authorize, pls sign up first");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	const isValidPhoneNumber = (phoneNumber: string) => {
		const regex = /^(070|080|081|090|091)\d{8}$/;
		return regex.test(phoneNumber);
	};

	const calculateAge = (dob: string) => {
		const birthDate = new Date(dob);
		const today = new Date();
		let age = today.getFullYear() - birthDate.getFullYear();
		const monthDiff = today.getMonth() - birthDate.getMonth();

		// Adjust age if birthday hasn't occurred yet this year
		if (
			monthDiff < 0 ||
			(monthDiff === 0 && today.getDate() < birthDate.getDate())
		) {
			age--;
		}

		return age;
	};

	const isValidNIN = (nin: string) => nin.length === 11;

	const isValidBVN = (bvn: string) => bvn.length === 11;

	const isValidString = (value: string) => value.trim() !== "";

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const value = e.target.value;

		setFormData({
			...formData,
			[name]: value,
		});

		switch (name) {
			case "firstName":
			case "lastName":
				setErrors({
					...errors,
					[name]: isValidString(value) ? "" : `${name} cannot be empty`,
				});
				break;
			case "phoneNumber":
				setErrors({
					...errors,
					phoneNumber: isValidPhoneNumber(value)
						? ""
						: "Invalid Nigerian phone number",
				});
				break;
			case "nin":
				setErrors({
					...errors,
					nin: isValidNIN(value) ? "" : "NIN must be 11 digits",
				});
				break;
			case "bvn":
				setErrors({
					...errors,
					bvn: isValidBVN(value) ? "" : "BVN must be 11 digits",
				});
				break;
			case "isStaff":
				setErrors({
					...errors,
					isStaff: isValidString(value) ? "" : "Select an option",
				});
				break;
			case "dateOfBirth":
				setErrors({
					...errors,
					dateOfBirth:
						calculateAge(value) >= 16
							? ""
							: "You must be at least 16 years old",
				});
				break;
			case "businessName":
			case "businessType":
			case "identificationType":
			case "identificationId":
				setErrors({
					...errors,
					[name]: isValidString(value) ? "" : `${name} cannot be empty`,
				});
				break;
			default:
				if (value === "") {
					setErrors({ ...errors, [name]: `${name} cannot be empty` });
				} else {
					setErrors({ ...errors, [name]: "" });
				}
		}
	};

	const isFormValid = () => {
		if (
			!formData.firstName ||
			!formData.lastName ||
			!formData.phoneNumber ||
			!formData.dateOfBirth ||
			!formData.nin ||
			!formData.bvn ||
			!formData.isStaff ||
			!proofOfIdentification ||
			errors.firstName ||
			errors.lastName ||
			errors.phoneNumber ||
			errors.dateOfBirth ||
			errors.nin ||
			errors.bvn ||
			errors.isStaff
		) {
			return false;
		}

		if (formData.isStaff === "Yes") {
			if (
				!formData.businessName ||
				!formData.businessType ||
				!formData.identificationType ||
				!formData.identificationId ||
				errors.businessName ||
				errors.businessType ||
				errors.identificationType ||
				errors.identificationId
			) {
				return false;
			}
		}

		return true;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!proofOfIdentification) {
			toast.error("Please upload Proof of identification");

			return;
		}

		if (!userData?.publicId) {
			navigate("/sign-up/individual");

			toast.error("pls sign up first");

			return;
		}

		if (isFormValid()) {
			const data = new FormData();

			data.append("publicId", userData.publicId);
			for (const key in formData) {
				if (key === "isStaff") {
					let isStaff = false;
					if (formData.isStaff === "Yes") isStaff = true;

					data.append("isStaff", `${isStaff}`);
				} else {
					data.append(key, formData[key as keyof typeof formData]);
				}
			}
			data.append("proofOfIdentification", proofOfIdentification);

			try {
				toast.promise(
					axios.put(
						API_BASE_URL + "Auth/RegisterUserPersonalInformation",
						data,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								Accept: "application/json",
							},
						}
					),
					{
						loading: "Submitting...",
						success: (res) => {
							console.log(res);
							if (res.data.isSuccessful) {
								setUserData(res.data);

								navigate(
									"/sign-up/individual/personal-information/country-information"
								);

								return res.data.remark || "Submitted successfully!";
							}

							throw new Error(
								res.data.remark || "An error occurred, please try again!"
							);
						},
						error: (err) => {
							if (axios.isAxiosError(err)) {
								return (
									err.response?.data.title ||
									err.response?.data.remark ||
									err.message
								);
							}
							return err.message;
						},
					}
				);
			} catch (error) {
				toast.error("Error submitting the form");
			}
		} else {
			console.log("Form is not valid");
		}
	};

	return (
		<div className={styles.personalInformation}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back to="/sign-up/individual" fixed />

				<div className={styles.content}>
					<h6>Personal Information</h6>

					<p className={styles.sub}>
						Please provide the information as stated on your valid means of
						identity.
					</p>

					<form onSubmit={handleSubmit}>
						<InputField
							label="First Name"
							name="firstName"
							autoComplete="given-name"
							placeholder="Enter first name"
							value={formData.firstName}
							onChange={handleInputChange}
							error={errors.firstName}
							isRequired
						/>

						<InputField
							label="Middle Name"
							name="middleName"
							autoComplete="additional-name"
							placeholder="Enter middle name"
							value={formData.middleName}
							onChange={handleInputChange}
						/>

						<InputField
							label="Last Name"
							name="lastName"
							autoComplete="family-name"
							placeholder="Enter last name"
							value={formData.lastName}
							onChange={handleInputChange}
							error={errors.lastName}
							isRequired
						/>

						<InputField
							label="Date of Birth"
							name="dateOfBirth"
							type="date"
							placeholder="DD-MM-YYYY"
							value={formData.dateOfBirth}
							onChange={handleInputChange}
							error={errors.dateOfBirth}
							isRequired
						/>

						<InputField
							label="Phone Number"
							name="phoneNumber"
							type="number"
							placeholder="Enter phone number"
							value={formData.phoneNumber}
							onChange={handleInputChange}
							error={errors.phoneNumber}
							isRequired
						/>

						<InputField
							label="Bvn"
							name="bvn"
							placeholder="Enter bvn"
							value={formData.bvn}
							onChange={handleInputChange}
							error={errors.bvn}
							isRequired
							type="number"
						/>

						<InputField
							label="Nin"
							name="nin"
							type="number"
							placeholder="Enter nin"
							value={formData.nin}
							onChange={handleInputChange}
							error={errors.nin}
							isRequired
						/>

						<UploadBox
							label="Proof of identity"
							onUpload={(uploads) => setProofOfIdentification(uploads[0])}
							isRequired
						/>

						<InputField
							label="Are you a staff of any corporate organization?"
							name="isStaff"
							type="select"
							value={formData.isStaff}
							onChange={handleInputChange}
							selectOptions={[
								{ value: "Yes", label: "Yes" },
								{ value: "No", label: "No" },
							]}
							placeholder="Select answer"
							error={errors.isStaff}
							isRequired
						/>

						{formData.isStaff === "Yes" && (
							<InputField
								label="Organization's Name"
								name="businessName"
								placeholder="Enter business name"
								value={formData.businessName}
								onChange={handleInputChange}
								error={errors.businessName}
								isRequired
							/>
						)}

						{formData.isStaff === "Yes" && (
							<InputField
								label="Organization Type"
								name="businessType"
								type="select"
								value={formData.businessType}
								onChange={handleInputChange}
								selectOptions={businessTypes}
								placeholder="Select organization type"
								error={errors.businessType}
								isRequired
							/>
						)}

						{formData.isStaff === "Yes" && (
							<InputField
								label="Organization Identification Type"
								name="identificationType"
								type="select"
								value={formData.identificationType}
								onChange={handleInputChange}
								selectOptions={identificationTypes}
								placeholder="Select identification type"
								error={errors.identificationType}
								isRequired
							/>
						)}

						{formData.isStaff === "Yes" && (
							<InputField
								label="Organization's Identification Number"
								name="identificationId"
								placeholder="Identification number"
								value={formData.identificationId}
								onChange={handleInputChange}
								error={errors.identificationId}
								isRequired
							/>
						)}

						<button className="btn" type="submit" disabled={!isFormValid()}>
							Proceed
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default PersonalInformation;
