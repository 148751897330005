import { createContext, ReactNode, useContext, useState } from "react";
import toast from "react-hot-toast";
import { UserType } from "./types";

interface AuthType {
	userData: UserType;
	setUserData: (data: Partial<UserType>) => void;
	setIsLoggedIn: (data: boolean) => void;
	refresh: () => void;
	refreshCount: number;
	isLoggedIn: boolean;
}

const Auth = createContext<AuthType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [refreshCount, setRefreshCount] = useState(0);
	const [isLoggedIn, setIsLoggedIn] = useState(() => {
		const isLoggedIn = localStorage.getItem("isLoggedIn");
		if (isLoggedIn === "true") return true;

		return false;
	});

	const setupAutoLogout = (validTo?: string) => {
		if (validTo) {
			const expirationTime = new Date(validTo).getTime();
			const currentTime = new Date().getTime();

			const timeUntilLogout = expirationTime - currentTime;

			if (timeUntilLogout > 0) {
				setTimeout(() => {
					toast.error("Session expired. Please log in again.");
					localStorage.removeItem("userData");
					localStorage.removeItem("isLoggedIn");
					setIsLoggedIn(false);
					setUserDataState(undefined);
					window.location.href = "/log-in";
				}, timeUntilLogout);
			}
		}
	};

	const [userData, setUserDataState] = useState<UserType>(() => {
		const storedData = localStorage.getItem("userData");

		if (storedData) {
			const parsedData = JSON.parse(storedData) as UserType;
			setupAutoLogout(parsedData?.validTo);

			const now = new Date();
			if (parsedData?.validTo && new Date(parsedData.validTo) > now) {
				return parsedData;
			}
		}
	});

	const setUserData = (data: Partial<UserType>) => {
		if (data === undefined) {
			setUserDataState(undefined);
			localStorage.removeItem("userData");
		} else {
			setUserDataState((prevData) => {
				const newData = { ...prevData!, ...data };
				localStorage.setItem("userData", JSON.stringify(newData));
				return newData;
			});
		}
	};

	const refresh = () => {
		setRefreshCount((prev) => prev + 1);
	};

	return (
		<Auth.Provider
			value={{
				userData,
				setUserData,
				refresh,
				refreshCount,
				setIsLoggedIn,
				isLoggedIn,
			}}
		>
			{children}
		</Auth.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export const useAuth = () => {
	const context = useContext(Auth);

	if (!context) {
		throw new Error("useOnboarding must be used within an AuthProvider");
	}

	return context;
};
