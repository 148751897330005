import React, { useEffect, useRef } from "react";
import { formatDate } from "../../../utils";
import styles from "./MessageModelStyles.module.scss";
import { User } from "./Support";

interface MessageModelProps {
	onClose: () => void;
	onResolve: () => void;
	message: User;
}

const MessageModel: React.FC<MessageModelProps> = ({
	onClose,
	message,
	onResolve,
}) => {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleOutsideClick = (e: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
				onClose();
			}
		};
		document.addEventListener("mousedown", handleOutsideClick);
		return () => document.removeEventListener("mousedown", handleOutsideClick);
	}, [onClose]);

	const copyToClipboard = () => {
		navigator.clipboard.writeText(message.email);
		alert("Email copied to clipboard!");
	};

	return (
		<div className={styles.overlay}>
			<div className={styles.modal} ref={modalRef}>
				<div className={styles.header}>
					<h6>
						Message from {message.firstName} {message.lastName}
					</h6>
					<button className={styles.closeButton} onClick={onClose}>
						&times;
					</button>
				</div>

				<div className={styles.content}>
					<p>
						<strong>Email:</strong> {message.email}{" "}
						<button
							className={styles.copyButton}
							onClick={copyToClipboard}
							aria-label="Copy email to clipboard"
						>
							Copy
						</button>
					</p>
					<p>
						<strong>Phone:</strong> {message.phoneNumber}
					</p>
					<p>
						<strong>Status:</strong>{" "}
						{message.isResolved ? "Resolved" : "Unresolved"}
					</p>
					<p>
						<strong>Message:</strong> {message.message}
					</p>
					<p>
						<strong>Date:</strong> {formatDate(message.date)}
					</p>
				</div>

				<div className={styles.actions}>
					{!message.isResolved && (
						<button className={styles.resolveButton} onClick={onResolve}>
							Resolve
						</button>
					)}
					<button className={styles.cancelButton} onClick={onClose}>
						Close
					</button>
				</div>
			</div>
		</div>
	);
};

export default MessageModel;
