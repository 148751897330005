import jsPDF from "jspdf";
import React, { FC, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ExportSvg } from "../../../assets/svg/Svg";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { formatDate } from "../../../utils";
import Pagination from "../../Table/Pagination";
import StatusIndicator from "../../Table/StatusIndicator";
import TableActions from "../../Table/TableActions";
import MessageModel from "./MessageModel";
import styles from "./styles.module.scss";

interface SupportProps {}

export interface User {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	message: string;
	phoneNumber: string;
	date: string;
	isResolved: boolean;
}

const Support: FC<SupportProps> = (): JSX.Element => {
	const { userData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [users, setUser] = useState<User[]>([]);
	const [message, setMessage] = useState<User>();
	const [filterStatus, setFilterStatus] = useState("All");
	const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(0);
	const [showModel, setShowModel] = useState(false);

	const handlePageChange = (page: number) => {
		setCurrentPage(page);
	};

	const fetchData = async () => {
		const getUserRes = await axiosFetch({
			method: "GET",
			url: `ContactUsResponses/GetAllContactUs?${
				filterStatus === "All"
					? ""
					: `isResolved=${filterStatus === "Resolved" ? "true" : "false"}&`
			}pageNumber=${currentPage}&pageSize=10`,
			requestConfig: {
				headers: {
					Authorization: `Bearer ${userData?.token}`,
				},
			},
		});

		setUser(getUserRes?.contactUsViewModel || []);
		setTotalPages(getUserRes?.totalCount || 0);
	};

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, filterStatus]);

	const exportToPDF = () => {
		const selectedUserData = users.filter((user) =>
			selectedUsers.includes(user.id)
		);

		if (selectedUserData.length === 0) {
			toast.error("Select a user");

			return;
		}

		const doc = new jsPDF();

		let y = 10;
		const lineHeight = 10;
		const pageHeight = doc.internal.pageSize.height;

		selectedUserData.forEach((user) => {
			const userText = `
			User: ${user.firstName}${user.lastName} (${user.email})

			Message: ${user.message}

			isResolved: ${user.isResolved ? "True" : "False"}
		`;

			const textLines = doc.splitTextToSize(userText, 180);

			if (y + textLines.length * lineHeight > pageHeight) {
				doc.addPage();
				y = 10;
			}

			doc.text(textLines, 10, y);
			y += textLines.length * lineHeight;
		});

		doc.save("selected_users.pdf");
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setSelectedUsers(users.map((user) => user.id));
		} else {
			setSelectedUsers([]);
		}
	};

	const handleSelectUser = (userId: number) => {
		setSelectedUsers((prevSelected) =>
			prevSelected.includes(userId)
				? prevSelected.filter((id) => id !== userId)
				: [...prevSelected, userId]
		);
	};

	const nav = [
		{
			name: "All",
			filter: "All",
		},
		{
			name: "Resolved",
			filter: "Resolved",
		},
		{
			name: "Unresolved",
			filter: "Unresolved",
		},
	];

	const resolveUser = async (id: number) => {
		toast.promise(
			axiosFetch({
				method: "PUT",
				url: "ContactUsResponses/ResolveContactUs",
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
					data: {
						id,
						resolvedBy: userData?.publicId,
					},
				},
			}),
			{
				loading: "Resolving...",
				success: (res) => {
					if (res.isSuccessful) {
						fetchData();
						return res.remark || "Updated successfully!";
					}

					throw new Error(res.remark || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.support}>
			<div className={styles.header}>
				<p className={styles.title}>Contact US</p>

				<div>
					<button type="button" className="btn-secondary" onClick={exportToPDF}>
						<ExportSvg className="" />

						<p>Export</p>
					</button>
				</div>
			</div>

			<div className={styles.nav}>
				{nav.map((nv, i) => (
					<button
						onClick={() => setFilterStatus(nv.filter)}
						key={i}
						type="button"
						className={`${filterStatus === nv.filter && styles.active}`}
					>
						{nv.name}
					</button>
				))}
			</div>

			<div className={styles.tableContainer}>
				<div className={styles.table}>
					<table>
						<thead>
							<tr>
								<th align="center">
									<input
										type="checkbox"
										checked={selectedUsers.length === users.length}
										onChange={handleSelectAll}
									/>
								</th>

								<th align="left">User</th>

								<th align="left">Phone</th>

								<th align="left">Date</th>

								<th align="left">Message</th>

								<th align="center">Status</th>

								<th align="center">Action</th>
							</tr>
						</thead>
						<tbody>
							{users.map((user) => (
								<tr key={user.id}>
									<td align="center">
										<input
											type="checkbox"
											checked={selectedUsers.includes(user.id)}
											onChange={() => handleSelectUser(user.id)}
										/>
									</td>

									<td align="left" className={styles.user}>
										<p>
											{user.firstName} {user.lastName}
										</p>
										<p>{user.email}</p>
									</td>

									<td align="left">
										<p style={{ textWrap: "nowrap" }}>{user.phoneNumber}</p>
									</td>

									<td align="left">
										<p style={{ textWrap: "nowrap" }}>
											{formatDate(user.date)}
										</p>
									</td>

									<td align="left">
										<p style={{ textWrap: "nowrap" }}>
											{user.message.slice(0, 30)}
											{user.message.length > 30 && "..."}
										</p>
									</td>

									<td align="center">
										<StatusIndicator
											color={user.isResolved ? "green" : "red"}
											status={user.isResolved ? "Resolved" : "Unresolved"}
										/>
									</td>

									<td align="center">
										<TableActions
											actions={
												user.isResolved
													? [
															{
																label: "View more",
																onClick: () => {
																	setMessage(user);
																	setShowModel(true);
																},
															},
													  ]
													: [
															{
																label: "View more",
																onClick: () => {
																	setMessage(user);
																	setShowModel(true);
																},
															},
															{
																label: "Resolved",
																onClick: () => resolveUser(user.id),
															},
													  ]
											}
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</div>

			{totalPages > 10 && (
				<Pagination
					total={totalPages / 10}
					current={currentPage}
					onPageChange={handlePageChange}
				/>
			)}

			{showModel && message && (
				<MessageModel
					message={message}
					onResolve={() => {
						setShowModel(false);
						resolveUser(message.id);
					}}
					onClose={() => {
						setShowModel(false);
					}}
				/>
			)}
		</div>
	);
};

export default Support;
