import axios from "axios";
import React, { FormEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { API_BASE_URL } from "../../../api/adminBaseApi";
import user from "../../../assets/images/user.png";
import { CameraSvg } from "../../../assets/svg/StartsSvg";
import { ArrowSvg } from "../../../assets/svg/Svg";
import { useAuth } from "../../../context/auth";
import useAxiosFunction from "../../../hooks/useAxiosFunction";
import { validatePassword } from "../../../utils";
import InputField from "../../InputField/InputField";
import styles from "./styles.module.scss";

const Settings: React.FC = () => {
	const { axiosFetch } = useAxiosFunction();
	const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(true);
	const [isResetButtonDisabled, setIsResetButtonDisabled] = useState(true);
	const [active, setActive] = useState("Edit");
	const { userData, setUserData } = useAuth();
	const [profileImage, setProfileImage] = useState(
		userData?.profileImage?.startsWith("https") ? userData?.profileImage : user
	);

	const [profileFormData, setProfileFormData] = useState({
		firstName: userData?.firstName || "",
		lastName: userData?.lastName || "",
		phoneNumber: userData?.phoneNumber || "",
		email: userData?.email || "",
	});
	const [profileErrors, setProfileErrors] = useState({
		firstName: "",
		lastName: "",
		phoneNumber: "",
		email: "",
	});

	const [passwordFormData, setPasswordFormData] = useState({
		newPassword: "",
		confirmPassword: "",
	});
	const [passwordErrors, setPasswordErrors] = useState({
		newPassword: "",
		confirmPassword: "",
	});

	const uploadProfilePic = async (profileImage: string) => {
		toast.promise(
			axiosFetch({
				method: "PUT",
				url: "/Users/UpdateUserProfileImage",
				requestConfig: {
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
					data: {
						profileImage,
						lastModifiedBy: userData?.publicId,
					},
				},
			}),
			{
				loading: "Updating profile image...",
				success: (res) => {
					if (res.isSuccessful) {
						setUserData(res);

						return res.remark || "Updated successfully!";
					}

					throw new Error(res.remark || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			const file = acceptedFiles[0];
			if (file) {
				const reader = new FileReader();
				reader.onload = () => setProfileImage(reader.result as string);
				reader.readAsDataURL(file);
				const data = new FormData();
				data.append("uploadFile", file);
				data.append("createdBy", userData?.publicId || "");
				data.append("isTransaction", "false");
				data.append("isProofOfTransactionRequest", "false");
				data.append("isReceiptOfPayment", "false");
				data.append("isReceiptOfRefund", "false");

				try {
					toast.promise(
						axios.post(API_BASE_URL + "Uploads/CreateUpload", data, {
							headers: {
								"Content-Type": "multipart/form-data",
								Accept: "application/json",
								Authorization: `Bearer ${userData?.token}`,
							},
						}),
						{
							loading: "Uploading...",
							success: (res) => {
								if (res.data.isSuccessful) {
									uploadProfilePic(res.data.filePath);
									return res.data.remark || "Uploaded successfully!";
								}

								throw new Error(
									res.data.remark || "An error occurred, please try again!"
								);
							},
							error: (err) => {
								if (axios.isAxiosError(err)) {
									return (
										err.response?.data.title ||
										err.response?.data.remark ||
										err.message
									);
								}
								return err.message;
							},
						}
					);
				} catch (error) {
					toast.error("Error submitting the form");
				}
			}
		},
		[userData]
	);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: {
			"image/png": [".png"],
			"image/jpeg": [".jpg", ".jpeg"],
		},
		maxSize: 5242880, // 5MB limit
	});

	const isValidPhoneNumber = (phoneNumber: string) => {
		const regex = /^(070|080|081|090|091)\d{8}$/;
		return regex.test(phoneNumber);
	};

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	const handleProfileInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setProfileFormData({
			...profileFormData,
			[name]: e.target.value,
		});

		if (e.target.value === "") {
			setProfileErrors({ ...profileErrors, [name]: `${name} cannot be empty` });
		} else if (name === "phoneNumber") {
			const phoneError = !isValidPhoneNumber(e.target.value);
			setProfileErrors({
				...profileErrors,
				phoneNumber: phoneError ? "Invalid Nigerian phone number" : "",
			});
		} else if (name === "email") {
			const emailError = !emailRegex.test(e.target.value);
			setProfileErrors({
				...profileErrors,
				email: emailError ? "Enter a valid email address" : "",
			});
		} else {
			setProfileErrors({ ...profileErrors, [name]: "" });
		}
	};

	const handlePasswordInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		setPasswordFormData({
			...passwordFormData,
			[name]: e.target.value,
		});

		// Handle errors
		if (name === "newPassword") {
			const passwordError = validatePassword(e.target.value);
			setPasswordErrors({
				...passwordErrors,
				newPassword: passwordError || "",
			});
		} else if (name === "confirmPassword") {
			const confirmError =
				e.target.value !== passwordFormData.newPassword
					? "Passwords do not match"
					: "";
			setPasswordErrors({
				...passwordErrors,
				confirmPassword: confirmError || "",
			});
		} else {
			setPasswordErrors({ ...passwordErrors, [name]: "" });
		}
	};

	const handleProfileSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		toast.promise(
			axiosFetch({
				method: "PUT",
				url: "/Users/UpdateUser",
				requestConfig: {
					data: {
						publicId: userData?.publicId,
						identificationId: userData?.identificationId || "NA",
						identificationType: userData?.identificationType || "NA",
						proofOfIdentification: userData?.proofOfIdentification,
						countryOfResidence: userData?.countryOfResidence,
						countryOfOrigin: userData?.countryOfOrigin,
						stateOfOrigin: userData?.stateOfOrigin,
						stateOfResidence: userData?.stateOfResidence,
						lastModifiedBy: userData?.publicId,
						...profileFormData,
					},
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Updating...",
				success: (res) => {
					if (res.isSuccessful) {
						return res.remark || "Saved!";
					}
					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	const handlePasswordSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const newPasswordError = validatePassword(passwordFormData.newPassword);
		const confirmPasswordError =
			passwordFormData.confirmPassword !== passwordFormData.newPassword
				? "Passwords do not match"
				: "";

		if (newPasswordError || confirmPasswordError) {
			setPasswordErrors({
				...passwordErrors,
				newPassword: newPasswordError || passwordErrors.newPassword,
				confirmPassword: confirmPasswordError || passwordErrors.confirmPassword,
			});
			return;
		}

		toast.promise(
			axiosFetch({
				method: "PUT",
				url: "/Auth/UpdatePassword",
				requestConfig: {
					data: {
						newPassword: passwordFormData.newPassword,
						confirmPassword: passwordFormData.confirmPassword,
					},
					headers: {
						Authorization: `Bearer ${userData?.token}`,
					},
				},
			}),
			{
				loading: "Resetting password...",
				success: (res) => {
					if (res.isSuccessful) {
						return res.remark || "Password reset successfully!";
					}
					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	useEffect(() => {
		const hasProfileErrors = Object.values(profileErrors).some(
			(error) => error !== ""
		);
		const isProfileEmptyField = Object.values(profileFormData).some(
			(value) => value === ""
		);

		setIsEditButtonDisabled(hasProfileErrors || isProfileEmptyField);
	}, [profileErrors, profileFormData]);

	useEffect(() => {
		const hasPasswordErrors = Object.values(passwordErrors).some(
			(error) => error !== ""
		);
		const isPasswordEmptyField = Object.values(passwordFormData).some(
			(value) => value === ""
		);

		setIsResetButtonDisabled(hasPasswordErrors || isPasswordEmptyField);
	}, [passwordErrors, passwordFormData]);

	return (
		<div className={styles.settingsContainer}>
			<h2>Settings</h2>

			<div className={styles.contents}>
				<div className={styles.menuItem}>
					<h3>Edit Profile</h3>

					<p>Change your profile details</p>

					<div className={styles.menuGroup}>
						<p>Profile</p>

						<div
							className={`${styles.profile} ${
								active === "Edit" && styles.active
							}`}
							onClick={() => setActive("Edit")}
						>
							<div className={styles.cta}>
								<div className={styles.icon}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" />
										<path d="M12.0002 14.5C6.99016 14.5 2.91016 17.86 2.91016 22C2.91016 22.28 3.13016 22.5 3.41016 22.5H20.5902C20.8702 22.5 21.0902 22.28 21.0902 22C21.0902 17.86 17.0102 14.5 12.0002 14.5Z" />
									</svg>
								</div>

								<div>
									<h5>Edit Profile</h5>

									<p>Edit your profile here</p>
								</div>
							</div>

							<ArrowSvg />
						</div>

						<p>Security</p>

						<div
							className={`${styles.resetPassword} ${
								active === "Reset" && styles.active
							}`}
							onClick={() => setActive("Reset")}
						>
							<div className={styles.cta}>
								<div className={styles.icon}>
									<svg
										width="24"
										height="24"
										viewBox="0 0 24 24"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path d="M11.9991 17.3498C12.8994 17.3498 13.6291 16.6201 13.6291 15.7198C13.6291 14.8196 12.8994 14.0898 11.9991 14.0898C11.0989 14.0898 10.3691 14.8196 10.3691 15.7198C10.3691 16.6201 11.0989 17.3498 11.9991 17.3498Z" />
										<path d="M18.28 9.53V8.28C18.28 5.58 17.63 2 12 2C6.37 2 5.72 5.58 5.72 8.28V9.53C2.92 9.88 2 11.3 2 14.79V16.65C2 20.75 3.25 22 7.35 22H16.65C20.75 22 22 20.75 22 16.65V14.79C22 11.3 21.08 9.88 18.28 9.53ZM12 18.74C10.33 18.74 8.98 17.38 8.98 15.72C8.98 14.05 10.34 12.7 12 12.7C13.66 12.7 15.02 14.06 15.02 15.72C15.02 17.39 13.67 18.74 12 18.74ZM7.35 9.44C7.27 9.44 7.2 9.44 7.12 9.44V8.28C7.12 5.35 7.95 3.4 12 3.4C16.05 3.4 16.88 5.35 16.88 8.28V9.45C16.8 9.45 16.73 9.45 16.65 9.45H7.35V9.44Z" />
									</svg>
								</div>

								<div>
									<h5>Reset Password</h5>

									<p>Change your password here</p>
								</div>
							</div>

							<ArrowSvg />
						</div>
					</div>
				</div>

				{active === "Edit" && (
					<div className={styles.formContainer}>
						<h4>Edit Profile</h4>

						<div className={styles.user}>
							<div {...getRootProps({ className: styles.dropzone })}>
								<input {...getInputProps()} />
								<img
									src={profileImage}
									alt="User Profile"
									className={styles.profileImage}
								/>
								{isDragActive ? <p>Drop the files here ...</p> : <CameraSvg />}
							</div>

							<p>
								{userData?.firstName} {userData?.lastName}
							</p>
						</div>

						<form
							className={styles.resetPasswordForm}
							onSubmit={handleProfileSubmit}
						>
							<InputField
								label="First Name"
								name="firstName"
								autoComplete="given-name"
								placeholder="Enter first name"
								value={profileFormData.firstName}
								onChange={handleProfileInputChange}
								error={profileErrors.firstName}
								isRequired
							/>

							<InputField
								label="Last Name"
								name="lastName"
								autoComplete="family-name"
								placeholder="Enter last name"
								value={profileFormData.lastName}
								onChange={handleProfileInputChange}
								error={profileErrors.lastName}
								isRequired
							/>

							<InputField
								label="Phone Number"
								name="phoneNumber"
								type="text"
								placeholder="Enter phone number"
								value={profileFormData.phoneNumber}
								onChange={handleProfileInputChange}
								error={profileErrors.phoneNumber}
								isRequired
							/>

							<InputField
								label="Email"
								name="email"
								type="email"
								placeholder="name@example.com"
								value={profileFormData.email}
								onChange={handleProfileInputChange}
								error={profileErrors.email}
								isRequired
							/>

							<button className="btn" disabled={isEditButtonDisabled}>
								Save
							</button>
						</form>
					</div>
				)}

				{active === "Reset" && (
					<div className={styles.formContainer}>
						<h4>Reset Password</h4>

						<form
							className={styles.resetPasswordForm}
							onSubmit={handlePasswordSubmit}
						>
							<InputField
								label="New Password"
								name="newPassword"
								type="password"
								placeholder="Enter new password"
								value={passwordFormData.newPassword}
								onChange={handlePasswordInputChange}
								error={passwordErrors.newPassword}
								isRequired
							/>

							<InputField
								label="Confirm New Password"
								name="confirmPassword"
								type="password"
								placeholder="Confirm new password"
								value={passwordFormData.confirmPassword}
								onChange={handlePasswordInputChange}
								error={passwordErrors.confirmPassword}
								isRequired
							/>

							<button className="btn" disabled={isResetButtonDisabled}>
								Reset Password
							</button>
						</form>
					</div>
				)}
			</div>
		</div>
	);
};

export default Settings;
