import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./chartsStyles.module.scss";

interface DonutChartProps {
	filter: "daily" | "weekly" | "monthly";
	starts: {
		name: string;
		total: number;
		percentage: string;
	}[];
}

const DonutChart: React.FC<DonutChartProps> = ({ filter, starts }) => {
	const chartOptions: ApexOptions = {
		chart: {
			type: "donut",
		},
		labels: ["Added Records", "Searched Record", "Funded", "Charges"],
		colors: ["#032B6B", "#0866FF", "#B2D0FF", "#FFA108"],
		plotOptions: {
			pie: {
				donut: {
					size: "50%",
					labels: {
						show: true,
						total: {
							show: true,
							label: "Average",
							formatter: () => "1.05",
						},
					},
				},
			},
		},
		legend: {
			show: false,
		},
	};

	return (
		<div className={styles.donutContainer}>
			<div className={styles.donutHeader}>
				<p>Statistics</p>

				<h3>
					<span>{filter}</span> activity
				</h3>
			</div>

			<div className={styles.donutContents}>
				<div className={styles.donutChart}>
					<ReactApexChart
						options={chartOptions}
						series={starts.map((start) => start.total)}
						type="donut"
						height={300}
					/>
				</div>

				<div className={styles.legend}>
					<ul>
						{starts?.map((start, i) => (
							<li key={i}>
								<span
									style={{
										backgroundColor:
											(start.name === "Added Records" && "#032B6B") ||
											(start.name === "Searched Record" && "#0866FF") ||
											(start.name === "Funded" && "#B2D0FF") ||
											"#FFA108",
									}}
								/>
								{start.name} - {start.percentage}
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default DonutChart;
