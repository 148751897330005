import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import { useFormContext } from "../../context/FormContext";
import RefundStep from "./RefundStep";
import {
	ReminderFormData,
	ReminderStep1,
	ReminderStep2,
	ReminderStep3,
} from "./ReminderSteps";
import {
	SearchFormData,
	SearchStep1,
	SearchStep2,
	SearchStep3,
} from "./SearchSteps";
import { FormData, Step1, Step2, Step3, Step4, Step5 } from "./Steps";
import styles from "./styles.module.scss";
import { UserFormData, UserSteps } from "./UserSteps";

export interface Credibility {
	totalCount: number;
	transactions?: {
		publicId: string;
		amountInvolved: number;
		beneficiaryRelationship: string;
		recipientRegistrationNumber: string;
		recipientBvn: string;
		businessName: string;
		recipientEmailAddress: string;
		isIndividual: boolean;
		recipientBankName: string;
		transactionType: string;
		recipientPublicId: string;
		dueDate: Date;
		isSettled: boolean;
		settlementDate: Date;
		lenderPublicId: string;
		recipientFirstName: string;
		recipientLastName: string;
		recipientMiddleName: string;
		recipientAccountNumber: string;
		lenderAccountNumber: string;
		lenderBankName: string;
		lenderBvn: string;
		isReminded: boolean;
		transactionInitiationDate: Date;
		currency: string;
		recipientNin: string;
	}[];
	isCredible: boolean;
}

const MultiStepForm: React.FC = () => {
	const [step, setStep] = useState(1);
	const [searchStep, setSearchStep] = useState(1);
	const [credibility, setCredibility] = useState<Credibility>();
	const [userStep, setUserStep] = useState(1);
	const [reminderStep, setReminderStep] = useState(1);
	const { userData } = useAuth();
	const { closeForm, type } = useFormContext();

	const initialFormData: FormData = {
		isIndividual: true,
		lenderPublicId: userData?.publicId || "",
		createdBy: userData?.publicId || "",
		amountInvolved: "",
		beneficiaryRelationship: "",
		transactionType: "",
		dueDate: "",
		recipientBankName: "",
		recipientAccountNumber: "",
		recipientFirstName: "",
		recipientLastName: "",
		recipientEmailAddress: "",
		recipientNin: "",
		hasNin: false,
		recipientBvn: "",
		hasBvn: false,
		businessName: "",
		recipientRegistrationNumber: "",
		lenderAccountNumber: "",
		lenderBankName: "",
		transactionInitiationDate: "",
		paymentReferenceNumber: "",
		receiptsOfPayment: undefined,
		proofsOfTransaction: undefined,
		currency: "Naira",
		isSettled: false,
	};

	const initialSearchFormData: SearchFormData = {
		isIndividual: true,
		search: "",
	};

	const initialReminderFormData: ReminderFormData = {
		isIndividual: true,
		bvn: "",
		nin: "",
		registrationNumber: "",
		businessName: "",
		reminderFrequency: "",
		createdBy: "",
		productName: "",
		requesterEmailAddress: "",
		bankName: "",
		accountNumber: "",
		isSpreadSheetReport: false,
		transactionType: "",
	};

	const initialUserFormData: UserFormData = {
		email: "",
		groupName: "",
		excelFile: undefined,
		identificationId: "",
		identificationType: "",
	};

	const [formData, setFormData] = useState<FormData>(initialFormData);
	const [reminderformData, setReminderFormData] = useState<ReminderFormData>(
		initialReminderFormData
	);
	const [userFormData, setUserFormData] =
		useState<UserFormData>(initialUserFormData);
	const [searchFormData, setFSearchData] = useState<SearchFormData>(
		initialSearchFormData
	);

	const handleChange = (
		name: string,
		value: string | number | boolean | File
	) => {
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSearchChange = (
		name: string,
		value: string | number | boolean | File
	) => {
		setFSearchData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleUserChange = (
		name: string,
		value: string | number | boolean | File
	) => {
		setUserFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleReminderChange = (
		name: string,
		value: string | number | boolean | File
	) => {
		setReminderFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const nextStep = () => setStep((prev) => prev + 1);
	const prevStep = () => setStep((prev) => prev - 1);
	const nextSearchFormStep = () => setSearchStep((prev) => prev + 1);
	const prevSearchFormStep = () => setSearchStep((prev) => prev - 1);
	const nextReminderFormStep = () => setReminderStep((prev) => prev + 1);
	const prevReminderFormStep = () => setReminderStep((prev) => prev - 1);
	const nextUserFormStep = () => setUserStep((prev) => prev + 1);
	const prevUserFormStep = () => setUserStep((prev) => prev - 1);
	const cancel = () => closeForm();

	const resetForm = () => {
		closeForm();
		setFormData(initialFormData);
		setStep(1);
	};

	const resetReminderForm = () => {
		closeForm();
		setReminderFormData(initialReminderFormData);
		setReminderStep(1);
	};

	return (
		<div className={styles.formContainer}>
			<div className={styles.header}>
				{type === "record" &&
					(step === 1 ? (
						<span />
					) : (
						<button
							onClick={prevStep}
							className={styles.backButton}
							disabled={step === 1}
						>
							&larr; Back
						</button>
					))}

				{type === "search" &&
					(searchStep === 1 ? (
						<span />
					) : (
						<button
							onClick={prevSearchFormStep}
							className={styles.backButton}
							disabled={searchStep === 1}
						>
							&larr; Back
						</button>
					))}

				{type === "onboard-user" &&
					(userStep === 1 ? (
						<span />
					) : (
						<button
							onClick={prevUserFormStep}
							className={styles.backButton}
							disabled={userStep === 1}
						>
							&larr; Back
						</button>
					))}

				{type === "reminder" &&
					(reminderStep === 1 ? (
						<span />
					) : (
						<button
							onClick={prevReminderFormStep}
							className={styles.backButton}
							disabled={reminderStep === 1}
						>
							&larr; Back
						</button>
					))}

				<button onClick={cancel} className={styles.cancelButton}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M4.80078 4.7998L12.0008 11.9998M12.0008 11.9998L19.2008 4.7998M12.0008 11.9998L19.2008 19.1998M12.0008 11.9998L4.80078 19.1998"
							stroke="#C4C4C5"
							strokeWidth="2"
							strokeLinecap="square"
							strokeLinejoin="round"
						/>
					</svg>
				</button>
			</div>

			{type === "record" && (
				<>
					{step === 1 && (
						<Step1
							prevStep={prevStep}
							step={step}
							formData={formData}
							handleChange={handleChange}
							nextStep={nextStep}
						/>
					)}
					{step === 2 && (
						<Step2
							step={step}
							formData={formData}
							handleChange={handleChange}
							nextStep={nextStep}
							prevStep={prevStep}
						/>
					)}
					{step === 3 && (
						<Step3
							step={step}
							formData={formData}
							handleChange={handleChange}
							nextStep={nextStep}
							prevStep={prevStep}
						/>
					)}
					{step === 4 && (
						<Step4
							step={step}
							formData={formData}
							handleChange={handleChange}
							nextStep={nextStep}
							prevStep={prevStep}
						/>
					)}
					{step === 5 && (
						<Step5
							step={step}
							formData={formData}
							handleChange={handleChange}
							nextStep={nextStep}
							prevStep={prevStep}
							resetForm={resetForm}
						/>
					)}
				</>
			)}

			{type === "search" && (
				<>
					{searchStep === 1 && (
						<SearchStep1
							prevStep={prevSearchFormStep}
							step={searchStep}
							formData={searchFormData}
							handleChange={handleSearchChange}
							nextStep={nextSearchFormStep}
						/>
					)}
					{searchStep === 2 && (
						<SearchStep2
							prevStep={prevSearchFormStep}
							step={searchStep}
							formData={searchFormData}
							handleChange={handleSearchChange}
							nextStep={nextSearchFormStep}
							setCredibility={setCredibility}
						/>
					)}
					{searchStep === 3 && (
						<SearchStep3
							prevStep={prevSearchFormStep}
							step={searchStep}
							formData={searchFormData}
							handleChange={handleSearchChange}
							nextStep={nextSearchFormStep}
							credibility={credibility}
						/>
					)}
				</>
			)}

			{type === "onboard-user" && (
				<>
					{userStep === 1 && (
						<UserSteps
							prevStep={prevUserFormStep}
							step={userStep}
							formData={userFormData}
							handleChange={handleUserChange}
							nextStep={nextUserFormStep}
						/>
					)}
				</>
			)}

			{type === "refund" && <RefundStep />}

			{type === "reminder" && (
				<>
					{reminderStep === 1 && (
						<ReminderStep1
							prevStep={prevReminderFormStep}
							step={reminderStep}
							formData={reminderformData}
							handleChange={handleReminderChange}
							nextStep={nextReminderFormStep}
						/>
					)}
					{reminderStep === 2 && (
						<ReminderStep2
							prevStep={prevReminderFormStep}
							step={reminderStep}
							formData={reminderformData}
							handleChange={handleReminderChange}
							nextStep={nextReminderFormStep}
						/>
					)}
					{reminderStep === 3 && (
						<ReminderStep3
							prevStep={prevReminderFormStep}
							step={reminderStep}
							formData={reminderformData}
							handleChange={handleReminderChange}
							nextStep={nextReminderFormStep}
							resetForm={resetReminderForm}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default MultiStepForm;
