import { Dispatch, FC, SetStateAction, useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../assets/images/Logo.png";
import { CloseSvg, HamburgerSvg } from "../../assets/svg/Svg";
import { useAuth } from "../../context/auth";
import { navData } from "../../data/constant";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface HeaderProps {}

const Nav = ({
	setIsNavOpen,
}: {
	setIsNavOpen: Dispatch<SetStateAction<boolean>>;
}) => {
	const { userData, isLoggedIn } = useAuth();

	return (
		<div className={styles.nav}>
			<div className={styles.topNav}>
				{navData.map((nav, index) => (
					<NavLink
						to={`/${slugify(nav)}`}
						key={index.toFixed()}
						onClick={() => setIsNavOpen(false)}
					>
						{nav}
					</NavLink>
				))}
			</div>

			<div className={styles.bottomNav}>
				{userData?.token && isLoggedIn ? (
					<NavLink
						onClick={() => setIsNavOpen(false)}
						to={`/dashboard`}
						className="btn-secondary"
					>
						Dashboard
					</NavLink>
				) : (
					<NavLink
						onClick={() => setIsNavOpen(false)}
						to={`/${slugify("Log In")}`}
						className="btn-secondary"
					>
						Log In
					</NavLink>
				)}

				<NavLink
					onClick={() => setIsNavOpen(false)}
					to={`/${slugify("Sign Up")}`}
					className="btn"
				>
					Sign Up
				</NavLink>
			</div>
		</div>
	);
};

const Header: FC<HeaderProps> = (): JSX.Element => {
	const [isNavOpen, setIsNavOpen] = useState(false);

	return (
		<>
			<nav className={styles.header}>
				<div
					className={`${styles.headerContainer} ${!isNavOpen && styles.close}`}
					id="mainContainer"
				>
					<NavLink to="/" className={styles.logo}>
						<img alt="Logo" src={Logo} />
					</NavLink>

					<Nav setIsNavOpen={setIsNavOpen} />

					<button
						className={styles.hamburger}
						type="button"
						onClick={() => setIsNavOpen((prev) => !prev)}
						aria-label="Hamburger menu"
						name="Hamburger menu"
					>
						{!isNavOpen ? (
							<HamburgerSvg className={styles.hamburgerSvg} />
						) : (
							<CloseSvg className={styles.hamburgerSvg} />
						)}
					</button>
				</div>
			</nav>

			<div className={styles.fakeHeader} />
		</>
	);
};

export default Header;
