import axios from "axios";
import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../api/adminBaseApi";
import Logo from "../../assets/images/Logo.png";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import UploadBox from "../InputField/UploadBox";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

const BusinessInformation: FC = (): JSX.Element => {
	const { setUserData, userData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const navigate = useNavigate();
	const [businessTypes, setBusinessTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();
	const [identificationTypes, setIdentificationTypes] = useState<
		{
			value: string;
			label: string | number | boolean;
		}[]
	>();
	const [proofOfIdentification, setProofOfIdentification] = useState<File>();
	const [formData, setFormData] = useState({
		businessName: "",
		businessType: "",
		identificationType: "",
		identificationId: "",
		dateOfBirth: "",
	});

	const [errors, setErrors] = useState({
		businessName: "",
		businessType: "",
		identificationType: "",
		identificationId: "",
		dateOfBirth: "",
	});

	useEffect(() => {
		const fetchData = async () => {
			const businessTypesRes = await axiosFetch({
				method: "GET",
				url: "/BusinessTypes/GetBusinessTypeByName?pageNumber=1&pageSize=100",
			});

			setBusinessTypes(
				businessTypesRes?.businessTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);

			const identificationTypesRes = await axiosFetch({
				method: "GET",
				url: "/IdentificationTypes/GetIdentificationTypes?pageNumber=1&pageSize=100",
			});

			setIdentificationTypes(
				identificationTypesRes?.identificationTypeViewModel?.map(
					(type: { name: string }) => ({
						value: type.name,
						label: type.name,
					})
				)
			);
		};

		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// useEffect(() => {
	// 	if (!userData?.publicId) {
	// 		navigate("/sign-up/corporate");

	// 		toast.error("You are not authorize, pls sign up first");
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [userData]);

	const isValidString = (value: string) => value.trim() !== "";

	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const value = e.target.value;

		setFormData({
			...formData,
			[name]: value,
		});

		switch (name) {
			case "businessName":
			case "businessType":
			case "identificationType":
			case "identificationId":
				setErrors({
					...errors,
					[name]: isValidString(value) ? "" : `${name} cannot be empty`,
				});
				break;
			default:
				if (value === "") {
					setErrors({ ...errors, [name]: `${name} cannot be empty` });
				} else {
					setErrors({ ...errors, [name]: "" });
				}
		}
	};

	const isFormValid = () => {
		if (
			!formData.businessName ||
			!formData.businessType ||
			!formData.identificationType ||
			!formData.identificationId ||
			!formData.dateOfBirth ||
			!proofOfIdentification ||
			errors.businessName ||
			errors.businessType ||
			errors.dateOfBirth ||
			errors.identificationType ||
			errors.identificationId
		) {
			return false;
		}

		return true;
	};

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!proofOfIdentification) {
			toast.error("Please upload Proof of identification");

			return;
		}

		if (!userData?.publicId) {
			navigate("/sign-up/corporate");

			toast.error("pls sign up first");

			return;
		}

		if (isFormValid()) {
			const data = new FormData();

			data.append("publicId", userData.publicId);
			for (const key in formData) {
				data.append(key, formData[key as keyof typeof formData]);
			}
			data.append("proofOfIdentification", proofOfIdentification);

			try {
				toast.promise(
					axios.put(
						API_BASE_URL + "Auth/RegisterUserBusinessInformation",
						data,
						{
							headers: {
								"Content-Type": "multipart/form-data",
								Accept: "application/json",
							},
						}
					),
					{
						loading: "Submitting...",
						success: (res) => {
							if (res.data.isSuccessful) {
								setUserData(res.data);

								navigate(
									"/sign-up/corporate/business-information/country-information"
								);

								return res.data.remark || "Submitted successfully!";
							}

							throw new Error(
								res.data || "An error occurred, please try again!"
							);
						},
						error: (err) => {
							if (axios.isAxiosError(err)) {
								return (
									err.response?.data.title ||
									err.response?.data.remark ||
									err.message
								);
							}
							return err.message;
						},
					}
				);
			} catch (error) {
				toast.error("Error submitting the form");
			}
		} else {
			console.log("Form is not valid");
		}
	};

	return (
		<div className={styles.businessInformation}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back to="/sign-up/corporate" fixed />

				<div className={styles.content}>
					<h6>Business Information</h6>

					<p className={styles.sub}>
						Please provide the information as stated on your business
						registration.
					</p>

					<form onSubmit={handleSubmit}>
						<InputField
							label="Business Name"
							name="businessName"
							placeholder="Enter business name"
							value={formData.businessName}
							onChange={handleInputChange}
							error={errors.businessName}
							isRequired
						/>

						<InputField
							label="Business Type"
							name="businessType"
							type="select"
							value={formData.businessType}
							onChange={handleInputChange}
							selectOptions={businessTypes}
							placeholder="Select organization type"
							error={errors.businessType}
							isRequired
						/>

						<InputField
							label="Year of Establishment"
							name="dateOfBirth"
							type="date"
							placeholder="DD-MM-YYYY"
							value={formData.dateOfBirth}
							onChange={handleInputChange}
							error={errors.dateOfBirth}
							isRequired
						/>

						<InputField
							label="Identification Type"
							name="identificationType"
							type="select"
							value={formData.identificationType}
							onChange={handleInputChange}
							selectOptions={identificationTypes}
							placeholder="Select identification type"
							error={errors.identificationType}
							isRequired
						/>

						<InputField
							label="Identification Number"
							name="identificationId"
							placeholder="Identification number"
							value={formData.identificationId}
							onChange={handleInputChange}
							error={errors.identificationId}
							isRequired
						/>

						<UploadBox
							label="Proof of identity"
							onUpload={(uploads) => setProofOfIdentification(uploads[0])}
							isRequired
						/>

						<button className="btn" type="submit" disabled={!isFormValid()}>
							Proceed
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default BusinessInformation;
