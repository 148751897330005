import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MultiStepForm from "../components/MultiStepForm/MultiStepForm ";
import Sidebar from "../components/Sidebar/Sidebar";
import TopBar from "../components/TopBar/TopBar";
import { useAuth } from "../context/auth";
import { useFormContext } from "../context/FormContext";
import styles from "./styles.module.scss";

const DashboardLayout = () => {
	const [isSidebarOpen, setSidebarOpen] = useState(false);
	const sidebarRef = useRef<HTMLDivElement>(null);
	const { userData } = useAuth();
	const navigate = useNavigate();
	const { isFormOpen, closeForm } = useFormContext();
	const formRef = useRef<HTMLDivElement>(null);
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const toggleSidebar = () => setSidebarOpen((prev) => !prev);

	useEffect(() => {
		if (!userData?.publicId) {
			navigate("/log-in");
			toast.error("You are not authorized, please log in first");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				sidebarRef.current &&
				!sidebarRef.current.contains(event.target as Node)
			) {
				setSidebarOpen(false);
			}
		};

		if (isSidebarOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		} else {
			document.removeEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isSidebarOpen]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (formRef.current && !formRef.current.contains(event.target as Node)) {
				closeForm();
			}
		};

		if (isFormOpen) {
			document.addEventListener("mousedown", handleClickOutside);
		}

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [isFormOpen, closeForm]);

	return (
		<div className={styles.dashboard}>
			<Sidebar
				ref={sidebarRef}
				closeSideBar={() => setSidebarOpen(false)}
				isOpen={isSidebarOpen}
			/>

			{isSidebarOpen && (
				<div className={styles.overlay} onClick={() => setSidebarOpen(false)} />
			)}

			{isFormOpen && (
				<div className={styles.overlayForForm} onClick={closeForm} />
			)}

			<div
				className={`${styles.content} ${
					isSidebarOpen ? styles.activeSideBar : ""
				}`}
			>
				<header id="mainContainer">
					<TopBar toggleSidebar={toggleSidebar} />
				</header>

				<main className={styles.mainContent} id="mainContainer">
					<Outlet />
				</main>

				<div
					ref={formRef}
					className={`${styles.formContainer} ${
						isFormOpen ? styles.formOpen : ""
					}`}
				>
					<MultiStepForm />
				</div>
			</div>
		</div>
	);
};

export default DashboardLayout;
