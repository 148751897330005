import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import countriesData from "../../assets/countries.json";
import Logo from "../../assets/images/Logo.png";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import InputField from "../InputField/InputField";
import { Back } from "../LogIn/LogIn";
import styles from "./styles.module.scss";

interface CountryInformationProps {
	who: "individual" | "business";
}

const CountryInformation: FC<CountryInformationProps> = ({
	who,
}): JSX.Element => {
	const { setUserData, userData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		countryOfResidence: "",
		stateOfResidence: "",
		countryOfOrigin: "",
		stateOfOrigin: "",
	});

	const [states, setStates] = useState<
		{
			code: string;
			name: string;
			subdivision: string | null;
		}[]
	>([]);

	useEffect(() => {
		if (!userData?.publicId) {
			navigate(
				who === "business"
					? "/sign-up/corporate/business-information"
					: "/sign-up/individual/personal-information"
			);

			toast.error("You are not authorize, pls sign up first");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData]);

	const handleCountryChange = (
		name: string,
		stateName: string,
		e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
	) => {
		const country = e.target.value;
		setFormData({ ...formData, [name]: country });

		const foundCountry = countriesData.find((c) => c.name === country);

		if (foundCountry && foundCountry.states.length === 0) {
			setFormData({ ...formData, [name]: country, [stateName]: country });
			setStates([]);
		} else {
			setStates(foundCountry ? foundCountry.states : []);
			setFormData({ ...formData, [name]: country, [stateName]: "" });
		}
	};

	// Handle State Change
	const handleStateChange = (
		name: string,
		e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
	) => {
		setFormData({ ...formData, [name]: e.target.value });
	};

	// Check if all required fields are filled to enable submit button
	const isButtonDisabled = Object.values(formData).some((value) => !value);

	const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!userData?.publicId) {
			navigate(
				who === "business"
					? "/sign-up/corporate/business-information"
					: "/sign-up/individual/personal-information"
			);

			toast.error("pls sign up first");

			return;
		}

		toast.promise(
			axiosFetch({
				method: "PUT",
				url: `/Auth/RegisterUserLocationInformation`,
				requestConfig: {
					data: {
						publicId: userData.publicId,
						...formData,
					},
				},
			}),
			{
				loading: "Submitting...",
				success: (res) => {
					if (res.isSuccessful) {
						setUserData(res);

						navigate("/log-in");

						return res.remark || "Submitted successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.countryInformation}>
			<div className={styles.right} id="mainContainer">
				<NavLink to="/" className={styles.logo}>
					<img alt="Logo" src={Logo} />
				</NavLink>

				<Back
					to={
						who === "business"
							? "/sign-up/corporate/business-information"
							: "/sign-up/individual/personal-information"
					}
					fixed
				/>

				<div className={styles.content}>
					<h6>Country Information</h6>

					<p className={styles.sub}>
						Please provide the information as stated on your valid means of
						identity.
					</p>

					<form onSubmit={handleSubmit}>
						{/* Country of Residence */}
						<InputField
							label={`Country of ${
								who === "business" ? "Operation" : "Residence"
							}`}
							name="countryOfResidence"
							type="select"
							value={formData.countryOfResidence}
							onChange={(name, e) =>
								handleCountryChange(name, "stateOfResidence", e)
							}
							selectOptions={countriesData.map((country) => ({
								value: country.name,
								label: country.name,
							}))}
							isRequired
						/>

						{/* State of Residence */}
						<InputField
							label={`State of ${
								who === "business" ? "Operation" : "Residence"
							}`}
							name="stateOfResidence"
							type="select"
							value={formData.stateOfResidence}
							onChange={handleStateChange}
							selectOptions={
								states.length > 0
									? states.map((state) => ({
											value: state.name,
											label: state.name,
									  }))
									: [
											{
												value: formData.countryOfResidence,
												label: formData.countryOfResidence,
											},
									  ]
							}
							isRequired
							disabled={!formData.countryOfResidence}
						/>

						{/* Country of Origin */}
						<InputField
							label="Country of Origin"
							name="countryOfOrigin"
							type="select"
							value={formData.countryOfOrigin}
							onChange={(name, e) =>
								handleCountryChange(name, "stateOfOrigin", e)
							}
							selectOptions={countriesData.map((country) => ({
								value: country.name,
								label: country.name,
							}))}
							isRequired
						/>

						{/* State of Origin */}
						<InputField
							label="State of Origin"
							name="stateOfOrigin"
							type="select"
							value={formData.stateOfOrigin}
							onChange={handleStateChange}
							selectOptions={
								states.length > 0
									? states.map((state) => ({
											value: state.name,
											label: state.name,
									  }))
									: [
											{
												value: formData.countryOfOrigin,
												label: formData.countryOfOrigin,
											},
									  ]
							}
							isRequired
							disabled={!formData.countryOfOrigin}
						/>

						<button className="btn" type="submit" disabled={isButtonDisabled}>
							Proceed
						</button>
					</form>
				</div>
			</div>
		</div>
	);
};

export default CountryInformation;
