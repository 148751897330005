import { FC, FormEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/auth";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import { validatePassword } from "../../utils";
import InputField from "../InputField/InputField";
import { Back } from "../LogIn/LogIn";
import Offering from "../Offering/Offering";
import styles from "./styles.module.scss";

interface SignUpCorporateProps {}

const SignUpCorporate: FC<SignUpCorporateProps> = (): JSX.Element => {
	const navigate = useNavigate();
	const { setUserData } = useAuth();
	const { axiosFetch } = useAxiosFunction();
	const [formData, setFormData] = useState({
		email: "",
		phoneNumber: "",
		password: "",
	});

	const [errors, setErrors] = useState({
		email: "",
		phoneNumber: "",
		password: "",
	});

	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	// Regular expressions
	const nigerianPhoneRegex = /^(080|081|090|091|070|071)\d{8}$/;
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

	// Real-time validation for form fields
	const validateField = (name: string, value: string) => {
		let error = "";

		switch (name) {
			case "phoneNumber":
				if (!nigerianPhoneRegex.test(value)) {
					error = "Enter a valid Nigerian phone number";
				}
				break;
			case "email":
				if (!emailRegex.test(value)) {
					error = "Enter a valid email address";
				}
				break;
			case "password":
				if (validatePassword(value)) {
					error = validatePassword(value);
				}
				break;
			default:
				break;
		}

		setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
	};

	// Handle input change and validate
	const handleInputChange = (
		name: string,
		e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
	) => {
		const value = e.target.value;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));

		validateField(name, value);
	};

	// Check if the form is valid (no errors and no empty required fields)
	useEffect(() => {
		const hasErrors = Object.values(errors).some((error) => error !== "");
		const isEmptyField = Object.values(formData).some((value) => value === "");

		// Disable button if there are any errors or if any required field is empty
		setIsButtonDisabled(hasErrors || isEmptyField);
	}, [errors, formData]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		toast.promise(
			axiosFetch({
				method: "POST",
				url: "/Auth/RegisterUserBasicInformation",
				requestConfig: {
					data: {
						email: formData.email,
						phoneNumber: formData.phoneNumber,
						password: formData.password,
						confirmPassword: formData.password,
						createdBy: formData.email,
						isIndividual: false,
					},
				},
			}),
			{
				loading: "Creating...",
				success: (res) => {
					if (res.isSuccessful) {
						setUserData(res);

						navigate("/verification");

						return res.remark || "Created successfully!";
					}

					throw new Error(res || "An error occurred, please try again!");
				},
				error: (err) => {
					return err.message;
				},
			}
		);
	};

	return (
		<div className={styles.signUpCorporate}>
			<div className={styles.left}>
				<Offering />
			</div>

			<div className={styles.right} id="mainContainer">
				<Back to="/" className={styles.back} />

				<div className={styles.content}>
					<h6>Corporate Account</h6>

					<form onSubmit={handleSubmit}>
						<InputField
							label="Phone Number"
							name="phoneNumber"
							type="tel"
							placeholder="08012345678"
							value={formData.phoneNumber}
							onChange={handleInputChange}
							error={errors.phoneNumber}
							isRequired
						/>

						<InputField
							label="Email"
							name="email"
							type="email"
							placeholder="name@example.com"
							value={formData.email}
							onChange={handleInputChange}
							error={errors.email}
							isRequired
						/>

						<InputField
							label="Password"
							name="password"
							type="password"
							placeholder="Enter your password"
							value={formData.password}
							onChange={handleInputChange}
							error={errors.password}
							isRequired
						/>

						<button className="btn" type="submit" disabled={isButtonDisabled}>
							Sign Up
						</button>

						<p className={styles.formFooter}>
							<span>Already have an account?</span>{" "}
							<NavLink to="/log-in">Log In</NavLink>
						</p>
					</form>
				</div>
			</div>
		</div>
	);
};

export default SignUpCorporate;
