import { forwardRef, useState } from "react";
import toast from "react-hot-toast";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Logo-white.png";
import user from "../../assets/images/user.png";
import { LogoutSvg } from "../../assets/svg/DashNavSvg";
import { AddSvg, SearchSvg } from "../../assets/svg/Svg";
import { useAuth } from "../../context/auth";
import { useFormContext } from "../../context/FormContext";
import { dashboardNavData } from "../../data/constant";
import useAxiosFunction from "../../hooks/useAxiosFunction";
import { slugify } from "../../utils";
import styles from "./styles.module.scss";

interface SidebarProps {
	isOpen: boolean;
	closeSideBar: () => void;
}

const Sidebar = forwardRef<HTMLDivElement, SidebarProps>(
	({ isOpen, closeSideBar }, ref) => {
		const [active, setActive] = useState("Dashboard");
		const { userData, setUserData, setIsLoggedIn } = useAuth();
		const navigate = useNavigate();
		const { axiosFetch } = useAxiosFunction();
		const { toggleForm } = useFormContext();

		const LogOut = () => {
			toast.promise(
				axiosFetch({
					method: "POST",
					url: "/Auth/Logout",
					requestConfig: {
						headers: {
							Authorization: `Bearer ${userData?.token}`,
						},
					},
				}),
				{
					loading: "Logging out...",
					success: (res) => {
						if (res.isSuccessful) {
							navigate("/");
							setUserData(undefined);
							setIsLoggedIn(false);
							localStorage.removeItem("isLoggedIn");

							return res.remark || "Log out successfully!";
						}

						throw new Error(res || "An error occurred, please try again!");
					},
					error: (err) => {
						return err.message;
					},
				}
			);
		};

		return (
			<div
				ref={ref}
				className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}
			>
				<nav>
					<NavLink
						to="/"
						className={styles.logo}
						onClick={() => closeSideBar()}
					>
						<img alt="Logo" src={Logo} />
					</NavLink>

					<ul>
						{dashboardNavData.map((nav, i) => {
							if (
								nav.name === "User Management" &&
								userData?.isIndividual &&
								userData?.userRole === "User"
							)
								return;

							if (
								nav.name === "Support" &&
								!(
									userData?.userRole === "Admin" ||
									userData?.userRole === "Customer Care"
								)
							)
								return;

							return (
								<li key={i}>
									<NavLink
										to={`/dashboard/${
											nav.name === "Dashboard" ? "" : slugify(nav.name)
										}`}
										onClick={() => {
											setActive(nav.name);
											closeSideBar();
										}}
										className={`${nav.name === active && styles.active}`}
									>
										{nav.icon}

										<p>
											{nav.name === "User Management" &&
											userData?.userRole === "Corporate"
												? "Staff Management"
												: nav.name}
										</p>
									</NavLink>
								</li>
							);
						})}

						<li>
							<button
								type="button"
								onClick={() => {
									closeSideBar();
									LogOut();
								}}
							>
								<LogoutSvg />
								<p>Logout</p>
							</button>
						</li>
					</ul>
				</nav>

				<div className={styles.profile}>
					<div className={styles.user}>
						<img
							src={
								userData?.profileImage?.startsWith("https")
									? userData?.profileImage
									: user
							}
							alt="User Profile"
						/>
					</div>

					<div>
						<p>
							{userData?.firstName} {userData?.lastName}
						</p>

						<p>{userData?.email}</p>
					</div>
				</div>

				<div className={styles.cta}>
					<button
						type="button"
						className="btn"
						onClick={() => {
							closeSideBar();
							toggleForm("record");
						}}
					>
						<div>
							<AddSvg />
						</div>
						<p>Add Record</p>
					</button>

					<button
						type="button"
						className="btn-secondary"
						onClick={() => {
							closeSideBar();
							toggleForm("search");
						}}
					>
						<SearchSvg color="white" />
						<p>Search Record</p>
					</button>
				</div>
			</div>
		);
	}
);

export default Sidebar;
