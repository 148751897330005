/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import { API_BASE_URL } from "../../../api/adminBaseApi";
import { UserType } from "../../../context/types";

export interface ResType {
	timestamp: string;
	data: number;
}

export const fetchSearchedRecords = async (
	userData: UserType,
	fromDate?: string,
	toDate?: string
): Promise<ResType[]> => {
	const response = await axios.get(
		`${API_BASE_URL}PaidApiCalls/GetPaidApiCallByUserId`,
		{
			params: {
				fromDate,
				toDate,
				userId: userData?.publicId,
				pageSize: 100,
				pageNumber: 1,
			},
			headers: {
				Authorization: `Bearer ${userData?.token}`,
			},
		}
	);

	return (
		response?.data?.paidApiCallViewModel?.map((record: any) => ({
			timestamp: record.dateCreated,
			data: record.price,
		})) || []
	);
};

export const fetchAddedRecords = async (
	userData: UserType,
	fromDate?: string,
	toDate?: string
): Promise<ResType[]> => {
	const response = await axios.get(
		`${API_BASE_URL}Transactions/GetTransactionsByUserId`,
		{
			params: {
				fromDate,
				toDate,
				userId: userData?.publicId,
				pageSize: 100,
				pageNumber: 1,
			},
			headers: {
				Authorization: `Bearer ${userData?.token}`,
			},
		}
	);

	return (
		response?.data?.transactionViewModel?.map((record: any) => ({
			timestamp: record.transactionInitiationDate,
			data: record.amountInvolved,
		})) || []
	);
};

export const fetchFunded = async (
	userData: UserType,
	fromDate?: string,
	toDate?: string
): Promise<ResType[]> => {
	const response = await axios.get(`${API_BASE_URL}Wallets/GetAllWallets`, {
		params: {
			fromDate,
			toDate,
			userId: userData?.publicId,
			pageSize: 100,
			pageNumber: 1,
		},
		headers: {
			Authorization: `Bearer ${userData?.token}`,
		},
	});

	return (
		response?.data?.walletViewModel?.map((record: any) => ({
			timestamp: new Date().toISOString(),
			data: record.balance,
		})) || []
	);
};

export const fetchCharges = async (
	userData: UserType,
	fromDate?: string,
	toDate?: string
): Promise<ResType[]> => {
	const response = await axios.get(`${API_BASE_URL}Payments/GetPayments`, {
		params: {
			fromDate,
			toDate,
			userId: userData?.publicId,
			pageSize: 100,
			pageNumber: 1,
		},
		headers: {
			Authorization: `Bearer ${userData?.token}`,
		},
	});

	return (
		response?.data?.paymentViewModel?.map((record: any) => ({
			timestamp: record.date,
			data: record.amount,
		})) || []
	);
};

export const fetchMetrics = async (
	userData: UserType,
	fromDate?: string,
	toDate?: string
) => {
	const [addedRecords, searchedRecords, funded, charges] = await Promise.all([
		fetchAddedRecords(userData, fromDate, toDate),
		fetchSearchedRecords(userData, fromDate, toDate),
		fetchFunded(userData, fromDate, toDate),
		fetchCharges(userData, fromDate, toDate),
	]);

	return {
		addedRecords,
		searchedRecords,
		funded,
		charges,
	};
};
